import Main from "../components/dashboard/Dashboard";

const Home = () =>
{
    return(
        <>
          <div id="app">
            <div className="main-wrapper">
              <Main/>
            </div>
          </div>
        </>
    )

}

export default Home;